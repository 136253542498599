import fetch from 'isomorphic-fetch'
import axios from 'axios'

import ApiError from '../ApiError'
import { constants as tokenConstants, IdentifyAppAPIURL } from 'utils/authUtils'

// ====== Default Values ======

const BASE_PATH = '/nextconnect'
const DEFAULT_FETCH_CONFIG = {
  headers: {
    Accept: '*/*'
  }
}

// ====== Helper Functions ======

const checkResponseContent = async (res, isAxiosRequest) => {
  if (isAxiosRequest) {
    return (
      res.headers['content-type']
        ? (res.headers['content-type'] === 'application/octet-stream'
          ? res.blob({ type: 'application/octet-stream' })
          : res.data
        )
        : res.status
    )
  } else {
    return (
      res.headers.get('content-type')
        ? (res.headers.get('content-type') === 'application/octet-stream' ? res.blob({ type: 'application/octet-stream' }) :
          (res.headers.get('content-type') === 'video/mp4' ||
            res.headers.get('content-type') === 'video/quicktime') ? res.body :
            res.json()
        )
        : res.status
    )
  }
}

class Request {
  constructor(...apiPath) {
    this.apiPath = apiPath ? apiPath.reduce((path, value) => {
      return `${path}/${value}`
    }, BASE_PATH)
      : BASE_PATH
  }

  includeAuthorization(auth) {
    if (auth === tokenConstants.ACCESS_TOKEN) {
      const token = localStorage.getItem(tokenConstants.ACCESS_TOKEN) || ''
      return { 'Authorization': `Bearer ${token}` }
    }
    if (auth === tokenConstants.REFRESH_TOKEN) {
      const token = localStorage.getItem(tokenConstants.REFRESH_TOKEN) || ''
      return { 'Authorization': `Bearer ${token}` }
    }
    if (auth === tokenConstants.RESET_PASSWORD_TOKEN) {
      const token = localStorage.getItem(tokenConstants.RESET_PASSWORD_TOKEN) || ''
      return { 'Authorization': `Bearer ${token}` }
    }
    return {}
  }
  async validateResponse(res, isAxiosRequest) {
    let data, headers
    try {
      data = await checkResponseContent(res, isAxiosRequest)
      headers = res.headers
    } catch (error) {
      throw new ApiError(res.status)
    }
    if (res.status >= 400) {
      throw new ApiError(res.status, data.errorCode, data.errorMessage)
    }
    return { data: data, headers: headers }
  }

  createRequestUrl(base, path) {
    return path.reduce((url, value) => {
      return `${url}/${value}`
    }, base)
  }
  deleteRequestUrl(base, path) {
    return path.reduce((url, value) => {
      if(value.charAt(0) === '?' && url.includes('devicesoftware/delete')) {
        return `${url}${value}`
      } else {
        return `${url}/${value}`
      }
    }, base)
  }

  async sendAxiosRequest(url, { auth = tokenConstants.ACCESS_TOKEN, ...config }, callBackFunc) {
    // extend header
    const { headers: configHeaders, ...custConfig } = config
    const { headers: defaultHeaders, ...defaultConfig } = DEFAULT_FETCH_CONFIG
    const authorization = this.includeAuthorization(auth)
    const headers = Object.assign({},
      configHeaders,
      defaultHeaders,
      authorization,
      IdentifyAppAPIURL
    )
    // create fetch configuration
    const fetchConfig = Object.assign({},
      { headers },
      custConfig,
      defaultConfig)
    const axiosConfig = {
      onUploadProgress: function (progressEvent) {
        const progressCount = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        callBackFunc(progressCount)
      },
      headers: fetchConfig.headers
    }
    try {
      let response = await axios.post(url, fetchConfig.body, axiosConfig)
      return await this.validateResponse(response, 'axiosCall')
    }
    catch (err) {
      let { status, data: { errorCode, errorMessage } } = err.response
      throw new ApiError(status, errorCode, errorMessage)
    }
  }

  async sendRequest(url, { auth = tokenConstants.ACCESS_TOKEN, ...config }) {
    // extend header
    const { headers: configHeaders, ...custConfig } = config
    const { headers: defaultHeaders, ...defaultConfig } = DEFAULT_FETCH_CONFIG
    const authorization = this.includeAuthorization(auth)
    const headers = Object.assign({},
      configHeaders,
      defaultHeaders,
      authorization,
      IdentifyAppAPIURL
    )
    // create fetch configuration
    const fetchConfig = Object.assign({},
      { headers },
      custConfig,
      defaultConfig)
    let response
    try {
      response = await fetch(url, fetchConfig)
    } catch (error) {
      throw new ApiError(500)
    }
    return await this.validateResponse(response)
  }

  sendRequestV2(url, { auth = tokenConstants.ACCESS_TOKEN, body }) {
    try {
      return new Promise((resolve, reject) => {
        let myHeaders = new Headers()
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem(auth)}`)
        myHeaders.append('Content-Type', 'application/json')
        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: body,
          redirect: 'follow'
        }
        fetch(url, requestOptions)
          .then(response => response.text())
          .then(result => resolve({ data: result.split('\n\n') }))
          .catch(error => reject({ error }))
      })
    } catch (error) {
      throw new ApiError(500)
    }
  }

  getRequest(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (query = {}, isAuth) => {
      return await this.sendRequest(this.appendQuery(url, query), { method: 'GET', auth: isAuth })
    }
  }

  postRequest(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (body, isAuth) => await this.sendRequest(url, {
      method: 'POST',
      auth: isAuth,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  }

  postRequestStream(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (body, isAuth) => await this.sendRequestV2(url, { auth: isAuth, body: JSON.stringify(body) })
  }

  postRequestFile(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (query = {}, body, isAuth, callBackFunc, axiosCall) => {
      if (axiosCall) {
        return await this.sendAxiosRequest(this.appendQuery(url, query), {
          auth: isAuth,
          body: body
        }, callBackFunc)
      }
      else {
        return await this.sendRequest(this.appendQuery(url, query), {
          method: 'POST',
          auth: isAuth,
          body: body
        })
      }
    }
  }
  postRequestWithQueryParams(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (query = {}, isAuth) => {
      return await this.sendRequest(this.appendQuery(url, query), {
        method: 'POST',
        auth: isAuth,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }
  }
  putRequestWithQueryParams(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (query = {}, isAuth) => {
      return await this.sendRequest(this.appendQuery(url, query), {
        method: 'PUT',
        auth: isAuth,
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }
  }
  putRequest(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (body, isAuth) => await this.sendRequest(url, {
      method: 'PUT',
      auth: isAuth,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  }

  putRequestFile(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (query = {}, body, isAuth) => await this.sendRequest(this.appendQuery(url, query), {
      method: 'PUT',
      auth: isAuth,
      body: body
    })
  }

  deleteRequest(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (body, isAuth) => await this.sendRequest(url, {
      method: 'DELETE',
      auth: isAuth,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  }
  deleteRequestWithoutParam(...path) {
    const url = this.deleteRequestUrl(this.apiPath, path)
    return async (body, isAuth) => await this.sendRequest(url, {
      method: 'DELETE',
      auth: isAuth,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  }

  patchRequest(...path) {
    const url = this.createRequestUrl(this.apiPath, path)
    return async (body, isAuth) => await this.sendRequest(url, {
      method: 'PATCH',
      auth: isAuth,
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
  }

  appendQuery(base, query = {}) {
    const queryString = Object.keys(query).map((key) => Array.isArray(query[key])
      ? this.buildQuery(key, ...query[key])
      : this.buildQuery(key, query[key])
    )
      .filter((str) => !!str)
      .reduce((acc, next) => acc ? `${acc}&${next}` : next, '')
    return queryString ? `${base}?${queryString}` : base
  }

  buildQuery(key, ...values) {
    return values
      .filter((str) => !!str)
      .reduce((acc, next) => acc ? `${acc}&${key}=${encodeURIComponent(next)}` : `${key}=${encodeURIComponent(next)}`, '')
  }
}

export default Request